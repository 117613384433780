import React from 'react';
import {
  Twitter,
  Headset,
  Mail,
  Phone,
  Instagram,
  Youtube,
  ArrowUp
} from 'lucide-react';

export default function Footer() {
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const footerLinks = {
    products: [
      { name: 'Game Hosting', href: '/games' },
      { name: 'VPS Services', href: '/vps' },
      { name: 'Web Hosting', href: '/webhosting' },
    ],
    company: [
      { name: 'About Us', href: '/about' },,
      { name: 'Partners', href: '/partners' },
    ],
    support: [
      { name: 'Help Center', href: 'https://clients.orionnodes.com/client/plugin/support_manager/client_tickets/departments/' },
      { name: 'Server Status', href: 'https://status.orionnodes.com' },
      { name: 'Knowledge Base(SOON)', href: '' },
      { name: 'Contact Support', href: 'https://discord.orionnodes.com' },
    ],
    legal: [
      { name: 'Terms of Service', href: 'https://orionnodes.gitbook.io/orionnodes/policies/tos' },
      { name: 'Privacy Policy', href: 'https://orionnodes.gitbook.io/orionnodes/policies/privacy' },
      { name: 'SLA', href: 'https://orionnodes.gitbook.io/orionnodes/policies/sla' },
      { name: 'Acceptable Use', href: 'https://orionnodes.gitbook.io/orionnodes/policies/aup' },
    ]
  };

  const socialLinks = [
    { icon: Headset, href: 'https://discord.orionnodes.com', label: 'Discord' },
  ];

  return (
    <footer className="bg-gray-900 text-white relative">
      {/* Main Footer Content */}
      <div className="max-w-7xl mx-auto px-4 py-12">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-5 gap-8">
          {/* Brand Section */}
          <div className="lg:col-span-1">
            <a href="/" className="flex items-center space-x-2 mb-6">
              <span className="text-3xl font-bold bg-gradient-to-r from-blue-500 via-purple-500 to-pink-500 text-transparent bg-clip-text">
                OrionNodes LLC
              </span>
            </a>
            <p className="text-gray-300 mb-6 font-semibold">
              Premium game hosting, serving gamers worldwide.
            </p>
            <div className="flex items-center space-x-4 max-w-fit">
              {socialLinks.map((social) => (
                <a
                  key={social.label}
                  href={social.href}
                  className="p-2 rounded-lg text-gray-400 hover:text-white hover:bg-gray-800/50 transition-all"
                  aria-label={social.label}
                >
                  <social.icon size={20} />
                </a>
              ))}
            </div>
          </div>

          {/* Links Sections */}
          {Object.entries(footerLinks).map(([category, links]) => (
            <div key={category}>
              <h3 className="text-lg font-bold mb-4 capitalize bg-gradient-to-r from-blue-500 to-purple-500 text-transparent bg-clip-text">
                {category}
              </h3>
              <ul className="space-y-3">
                {links.map((link) => (
                  <li key={link.name}>
                    <a
                      href={link.href}
                      className="text-gray-300 hover:text-white hover:bg-gray-800/50 transition-all rounded-lg py-1 px-2 -ml-2 inline-block"
                    >
                      {link.name}
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>

        {/* Contact Bar */}
        <div className="mt-12 pt-8 border-t border-gray-800/50">
          <div className="flex flex-col md:flex-row justify-between items-center gap-4">
            <div className="flex flex-wrap items-center gap-6 text-gray-300 font-bold">
              <a href="mailto:support@orionnodes.com" className="flex items-center hover:text-white hover:bg-gray-800/50 rounded-lg px-3 py-2 transition-all">
                <Mail size={16} className="mr-2" />
                support@orionnodes.com
              </a>
              <a href="https://discord.orionnodes.com" className="flex items-center hover:text-white hover:bg-gray-800/50 rounded-lg px-3 py-2 transition-all">
                <Phone size={16} className="mr-2" />
                Discord Support
              </a>
            </div>
            <button
              onClick={scrollToTop}
              className="p-2 rounded-lg text-gray-400 hover:text-white hover:bg-gray-800/50 transition-all hover:scale-105"
              aria-label="Scroll to top"
            >
              <ArrowUp size={22} />
            </button>
          </div>
        </div>

        {/* Bottom Bar */}
        <div className="mt-8 pt-8 border-t border-gray-800/50 flex flex-col md:flex-row justify-between items-center gap-4">
          <p className="text-gray-400">
            © {new Date().getFullYear()} OrionNodes LLC. All rights reserved.
          </p>
          <div className="flex flex-wrap justify-center gap-6 text-sm">

          </div>
        </div>
      </div>
    </footer >
  );
}